import {NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {provideHttpClient, withFetch} from "@angular/common/http";

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        provideHttpClient(withFetch())
        // provideClientHydration(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
